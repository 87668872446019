/* eslint-disable react/display-name */
import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import Layout from '../containers/layout';
import RenderSections from '../components/RenderSections';

export default ({ data }) => {
  const { sanityPage } = data;

  return (
    <Layout>
      {sanityPage && <SEO title={sanityPage.title || 'Untitled'} />}
      {sanityPage.content && sanityPage.content.length ? (
        <RenderSections sections={sanityPage.content} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  fragment BasicPage on SanityPage {
    id
    title
    description
    content {
      ... on SanityHero {
        _key
        _type
        heading
        tagline: _rawTagline
        backgroundImage {
          asset {
            url
            title
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
      ... on SanityImageSection {
        _key
        _type
        heading
        text: _rawText
        rtl
        image {
          alt
          caption
          asset {
            url
            title
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
      ... on SanityTextSection {
        _key
        _type
        centered
        heading
        text: _rawText
      }
      ... on SanityGallerySection {
        _key
        _type
        images {
          alt
          caption
          asset {
            url
            title
            fluid {
              src
              srcSet
              sizes
            }
            thumbnail: fluid(maxWidth: 300) {
              src
            }
          }
        }
      }
      ... on SanityYoutubeSection {
        _key
        _type
        heading
        videoID
      }
      ... on SanityProductSection {
        _key
        _type
        heading
        text: _rawText
        products {
          heading
          text: _rawText
          image {
            alt
            caption
            asset {
              url
              title
              fluid {
                src
                srcSet
                sizes
              }
              thumbnail: fluid(maxWidth: 300) {
                src
              }
            }
          }
        }
      }
      ... on SanityContactSection {
        _key
        _type
        heading
        text: _rawText
        address: _rawAddress
      }
    }
  }

  query($id: String!) {
    sanityPage(id: { eq: $id }) {
      ...BasicPage
    }
  }
`;
